@import url("https://fonts.googleapis.com/css?family=Inconsolata");

html,
body {
  background-color: #e7e7ea;
  font-family: "Inconsolata", monospace;
  font-size: 1.13em;
  font-weight: bold;
}

.container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
}

.btn {
  color: #101832;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.075em;
  padding: 0.8em 1em;
  width: 130%;
  margin-left: 4px;
  position: relative;
  align-self: center;
  text-transform: uppercase;
  border: 3px #00bcdd solid;
  border-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  border-image-slice: 1 1 0 0;
  z-index: 1;
  box-shadow: -0.5em 0.5em rgba(16, 24, 50, 0);
  transform-origin: left bottom;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.btn:before, .btn:after {
  border: 3px #00bcdd solid;
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
}
.btn:before {
  border-image: linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  border-image-slice: 1 1 0 1;
  left: -0.59em;
  top: 0.15em;
  width: 0.31em;
  height: 100%;
  transform: skewY(-45deg);
}
.btn:after {
  border-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  border-image-slice: 1 1 1 0;
  bottom: -0.61em;
  right: 0.16em;
  width: 100%;
  height: 0.31em;
  transform: skewX(-45deg);
}
.btn:hover {
  background-color: white;
  background-size: 90%;
  transform: translate(0.5em, -0.5em);
  box-shadow: -1em 1em 0.15em rgba(16, 24, 50, 0.1);
}
.btn:hover:before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwOTdkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  background-image: -webkit-linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  background-image: linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  height: calc(100% - 0.13em);
  border-image-slice: 1;
}
.btn:hover:after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: -webkit-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  width: calc(100% - 0.13em);
  border-image-slice: 1;
}

.cta {
  background-color: #00bcdd;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: -webkit-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  border-image: linear-gradient(45deg, #44e3ff 0%, #ff66ff 100%);
  border-image-slice: 1;
  color: white;
}
.cta:before {
  border-image-slice: 1;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwOTdkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  background-image: -webkit-linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  background-image: linear-gradient(45deg, #00bcdd 0%, #0097dd 100%);
  left: -0.75em;
  top: 0.15em;
}
.cta:after {
  border-image-slice: 1;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYmNkZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -moz-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: -webkit-linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  background-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  bottom: -0.75em;
  right: 0.15em;
}
.cta:hover {
  background: white;
  border-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  border-image-slice: 1;
  color: #101832;
}
.cta:hover:before {
  height: 100%;
}
.cta:hover:after {
  width: 100%;
}

.bg {
  background: none;
  border: 3px solid #A775E8;
  color: #A775E8;
}
.bg:before, .bg:after {
  background: #A775E8;
  border: 3px solid #A775E8;
}
.bg:hover {
  border-image: none;
}
.bg:hover:before, .bg:hover:after {
  background: #A775E8;
}

